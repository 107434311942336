function fitSize(image, size)
{
	if (!size || size <= 150) {
		return image;
	}

	newSize = '';
	if (size <= 256) {
		newSize = 'oneeighth';
	}
	else if (size <= 300) {
		newSize = 'optional';
	}
	else if (size <= 450) {
		newSize = 'medium';
	}
	else if (size <= 512) {
		newSize = 'quarter';
	}
	else if (size <= 800) {
		newSize = 'default';
	}
	else if (size <= 1024) {
		newSize = 'half';
	}
	else {
		newSize = 'full';
	}

	
	
	image = image.replace (new RegExp('thumbnail$'), newSize);

	return image;
}

(function() {
    var lastTime = 0;

    if (!window.requestAnimationFrameN)
        window.requestAnimationFrameN = function(callback, element) {
            var currTime = Date.now();
            var timeToCall = 20;
            var id = window.setTimeout(function() { callback(currTime + timeToCall); },
              timeToCall);
            lastTime = currTime + timeToCall;
            return id;
        };

    if (!window.cancelAnimationFrame)
        window.cancelAnimationFrame = function(id) {
            clearTimeout(id);
        };
}());

(function(){
	if(window.roundshot) return; // already included, abort

	var that = null;
	var speed = 1;

	window.roundshot = {
		queue: [],
		stamp: 0,
		frame: 0,

		animateBouncingItem: function(item) {
			var c_width = item.container.clientWidth;
			var img 	= item.container.childNodes[0];
			var i_width = img.clientWidth;
			var left 	= parseFloat(img.style.left);
			var margin  = Math.abs(c_width - i_width);

			if(c_width > i_width) {
				left = (c_width - i_width)/2;
			} else {
				left += (item.direction?-1:1)*speed*(that.frame/50);

				if(left < -margin) {
					left = -margin;
					item.direction = !item.direction;
				} else if(left > 0) {
					left = 0;
					item.direction = !item.direction;
				}
			}
			img.style.left = left +"px";
		},

		animateRotatingItem: function(item) {
			var img_1 	= item.container.childNodes[0];

			var i_width = img_1.clientWidth;
			var left 	= parseFloat(img_1.style.left);

			left -= 1;

			if(left < -i_width) {
				left += (i_width-1);
			}
			for(var i=0; i<item.container.childNodes.length; i++) {
				var img_1 = item.container.childNodes[i];
				img_1.style.left = (left + i*(i_width-1))+"px";
			}
		},

		animateItem: function(item){
			if(item.bounce) {
				that.animateBouncingItem(item);
			} else {
				that.animateRotatingItem(item);
			}
		},

		animateQueue: function(stamp) {
			setTimeout(function(){requestAnimationFrameN(that.animateQueue);}, 20);
			
			that.frame = parseInt(stamp - that.stamp);
			if(that.stamp > 0) {
				for(var i=0; i < window.roundshot.queue.length; i++) {
					that.animateItem(window.roundshot.queue[i]);
				}
			}
			that.stamp = stamp;
		},

		animate: function(image, container) {

			if (!container) {

				//{picture: 'tutaj link do obrazka, url do img bezpoÅ›rednio',
				// angle: 'kÄ…t w stopniach'}
	            var randomId = Math.random();
				var container_id = 'roundshot_container_' + randomId;
				var imglink_id = 'roundshot_imglink_' + randomId;
	                        var target = image.open_in_new_tab ? '_blank' : '_self';
				document.write('<div style="position:relative" id="'+container_id+'"><div target="'+target+'" style="display:block;" id="'+imglink_id+'"></div></div>');

				var container = document.getElementById(container_id);
	            var imglink = document.getElementById(imglink_id);

        	} else {
        		imglink = container.children().get(0);
        		container = container.get(0);
        	};

			container.style.width    = image.width?image.width+"px":"720px";
			container.style.height   = image.height?image.height+"px":"150px";
			container.style.overflow = "hidden";
			//container.style.margin   = "auto";
			container.style.position = "relative";
            imglink.href             = image.link;
			
			var img = document.createElement('img');
			image.picture = fitSize(image.picture, image.height);
			img.src                  = image.picture;
			img.style.height         = "100%";
			img.style.position       = "absolute";
			img.style.left 			 = 0;
			img.style.top 			 = 0;
			img.style.visiblity 	 = "hidden";
            img.style.border         = "none";
			if(navigator.userAgent.toLowerCase().indexOf('safari') != -1 && navigator.userAgent.toLowerCase().indexOf('chrome')!=-1)
				img.style.WebkitFilter      = "blur(0.2px)";
			
			img.onload = function () {

				img.style.visibility = "show";

				if(!image.bounce) {
					var width = img.clientWidth;
					var containerWidth = container.clientWidth;

					while(width <= (containerWidth + img.clientWidth)) {
						var _img = document.createElement('img');
						_img.src = image.picture;
						_img.style.height         = "100%";
						_img.style.position       = "absolute";
						_img.style.left 		  = 0;
						_img.style.top 			  = 0;
                        _img.style.border         = "none";
						if(navigator.userAgent.toLowerCase().indexOf('safari') != -1 && navigator.userAgent.toLowerCase().indexOf('chrome')!=-1)
							_img.style.WebkitFilter      = "blur(0.2px)";

						width += img.width;
						imglink.appendChild(_img);
					}
				}

				that.queue.push({'container': imglink,
					 'direction': true,
					 'bounce': image.bounce});
			};

			imglink.appendChild(img);
		}
	};

	that = window.roundshot;

	requestAnimationFrameN(that.animateQueue);
})();